import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageDisplay from '../ImageDisplayprofile.js';
import { Base_URL } from '../../Components/BaseURL.js';
import ConfirmationDialog from "../ConfirmationDialog_add.js";
import { FiUploadCloud } from "react-icons/fi";

const username = localStorage.getItem('unm');

const Profile_Public = () => {
  const [isImageEmpty, setIsImageEmpty] = useState(false);
  const handleImageCheck = (isEmpty) => {
    setIsImageEmpty(isEmpty);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [usrid, setUsrid] = useState(null);
   const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    InputUserId: "",
    InputNickName: "",
    InputCountry: "LK",
    InputProfilePic: "",
    Input64Img: "",
   InputCountryName: 'Sri Lanka', 
  });

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const ref = useRef();
  const ref64 = useRef();
  const delay = ms => new Promise(res => setTimeout(res, ms));
  // const [formData, setFormData] = useState({
 
  // });
  const selectedHandler = e => {
    let selectedFile = e.target.files[0];
    const maxSize = 700001;
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      setFileError(true);
      toast.error("File type must be png, jpg or jpeg");
      e.target.value = "";
    } else if (selectedFile && selectedFile.size > maxSize) {
      setFileError(true);
      toast.error("File size must be less than 700 KB");
      e.target.value = "";
    } else {
      setFileError(false);
      setFileErrorMessage("");
      setFile(selectedFile);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const countryRes = await axios.get(`${Base_URL}/masterdata/country`,  {
          withCredentials: true, // ensures that cookies (like JWT tokens) are sent along with requests
          headers: {
            'Content-Type': 'application/json'
          }
        });


        const profileRes = await axios.get(`${Base_URL}/profile/profilepubget`, {
          params: { username }, // Pass username as a query parameter
          withCredentials: true, // ensures that cookies (like JWT tokens) are sent along with requests
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (Array.isArray(countryRes.data)) {
          setData(countryRes.data); // Country data is an array
              } else {
          console.error("Expected array data for countries but got:", countryRes.data);
        }
        
        if (Array.isArray(profileRes.data) && profileRes.data.length > 0) {
          const userDataPublic = profileRes.data[0];
          setUsrid(userDataPublic.scsemppri_id);
          const initialData = {
            InputUserId: userDataPublic.scsemppri_id,
            InputNickName: userDataPublic.scsemppub_nickname,
            InputCountry: userDataPublic.scsemppub_countrycode,
            InputProfilePic: userDataPublic.scsemppri_picture,
          };
          setFormData(initialData);
          setInitialFormData(initialData);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to load data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const postImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("updateId", usrid); // Ensure this line is correct

    try {
      const res = await axios.post(`${Base_URL}/upimage/images/post`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data.filePath;  // Ensure this path is correct
    } catch (error) {
      console.error(error);
      toast.error("Error uploading image. Please try again later.");
      return null;
    }
  };
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: 'rgba(256,256,256, 0.3)',
            backgroundColor: 'rgba(256,256,256, 0.3)',
            height: 1,
            width: '100%'
        }}
    />
);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (JSON.stringify(initialFormData) === JSON.stringify(formData) && !file) {
      toast.info("No changes detected, submission prevented.");
      return;
    }

    let imageUrl = formData.InputProfilePic;

    if (file) {
      const uploadedImageUrl = await postImage(file);
      if (uploadedImageUrl) {
        imageUrl = uploadedImageUrl;
      }
    }

    const updatedFormData = { ...formData, InputProfilePic: imageUrl };

    try {
      await axios.post(`${Base_URL}/profile/profilepubpost`, updatedFormData);
      toast.success("Public Data Successfully updated.");
      await delay(1000);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Error submitting data. Please try again later.");
    }
  };

  const handleCountrySelect = (selectedCountryCode) => {
    setFormData({
      ...formData,
      InputCountry: selectedCountryCode,
    });
  };

  useEffect(() => {
    if (!file) {
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    const allowedFileTypes = ["jpg", "png", "gif"];
    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error(`File type must be ${allowedFileTypes.join(", ")}`);
      ref.current.value = "";
      return;
    }

    if (file.size > 700 * 1024) {
      toast.error("File with maximum size of 700KB is allowed");
      ref.current.value = "";
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      ref64.current.value = reader.result;
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const handleDeleteImage = async (userid) => {
    try {
      await axios.post(`${Base_URL}/profile/deleteimage`, { userid: userid });
      toast.success("Image Successfully deleted.");
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete image.");
    }
  };

  const handleConfirmation = (userid) => {
    handleDeleteImage(userid);
  };

  return (
    <>
        <div className="col-md-6 bg-light bg-black text-white w-100 d-flex text-center">
          <ToastContainer />
            
                      <legend>
              <span className="d-flex justify-content-center font-family-sans-serif">
                <b>PUBLIC DETAILS</b>
              </span>
              <h6 className="mt-1 mb-1 d-flex justify-content-center font-family-sans-serif">
                <small>
                Note: This information will be displayed in public and visible for all users.
              </small>
              </h6>
             <ColoredLine/>
    
            </legend>
             </div>
             <div className="col-md-6 bg-black text-white w-100">
             <form id="formpublic" className="form-horizontal" onSubmit={handleSubmit}>
      <div className="row row-cols-3 pt-3">
        <div className="col text-end">
          <label htmlFor="nameVariable" className="form-label">Name/Nickname</label>
        </div>
        <div className="col-8 d-grid gap-2 d-md-flex container flex-grow">
        <input
                type="text"
                className="form-control text-white bg-black fw-light font-family-sans-serif"
                id="InputNickName"
                name="InputNickName"
                value={formData.InputNickName}
                placeholder="Nickname"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    InputNickName: e.target.value,
                  })
                }
              />
        </div>
      </div>     
      <div className="row row-cols-3 pt-3">
        <div className="col text-end">
          <label htmlFor="nameVariable" className="form-label">Country</label>
        </div>
        <div className="col-8 d-grid gap-2 d-md-flex container flex-grow">
        <select
                className="form-select text-white bg-black fw-light cntrycss"
                onChange={(e) => handleCountrySelect(e.target.value)}
                name="InputCountry"
                value={formData.InputCountry}
              >
                <option value="">Select a country</option>
                {Array.isArray(data) && data.map((country) => (
                  <option key={country.csem_id} value={country.csem_country_code}>
                    {country.csem_country_name}
                  </option>
                ))}
              </select>
  
        </div>
      </div>
      <div className="p-2"></div>
      {isImageEmpty && ( 
         <div className="row row-cols-3 pt-3">
                  <div className="col text-end">
          <label htmlFor="nameVariable" className="form-label">Profile Picture</label>
        </div>
            <div className="col text-end Appf" style={{marginLeft: '12px'}}>
              {previewUrl ? (
                <img
                  height="120"
                  src={previewUrl}
                  alt="Preview"
                  className="mb-3"
                />
              ) : (
                <>
                  <label htmlFor="fileinput" className="labelf">
                    <h5 className="labelf">Upload Photo</h5>
                    <FiUploadCloud className="upload__icon" />
                    <p className="font-family-sans-serif text-nowrap">JPG, GIF or PNG files <br />supported, <br />Max file size 700 KB.</p>
                  </label>
                  <input type="file" id="fileinput" name="fileinput" className="inputf" onChange={selectedHandler} />
                </>
              )}
            </div>
          </div>
)}

          <div className="form-group row">


{!isImageEmpty && ( // Conditionally display the "Remove Image" button
<>
<div className="row row-cols-3 pt-3">
                  <div className="col text-end">
          <label htmlFor="nameVariable" className="form-label">Profile Picture</label>
        </div>
        <div className="col text-end">

        {usrid ? (
               <ImageDisplay
               imageId={usrid}
               onImageCheck={handleImageCheck}
             />
            ) : (
           <p></p>
            )}
              <div className="col d-flex align-items-center">
                <ConfirmationDialog
                  title="Confirmation"
                  description="Are you sure you want to remove the image?"
                  response={() => handleConfirmation(usrid)}
                >
                  {(showDialog) => (
                    <i
                      className="bi bi-trash p-2 rounded font-family-sans-serif text-nowrap"
                      onClick={showDialog}
                      style={{
                        color: 'rgb(255, 0, 0)',
                        fontSize: '0.9rem',
                        fontStyle: 'normal',
                        cursor: 'pointer',
                      }}
                    >
                      &nbsp;Delete Photo
                    </i>
                  )}
                </ConfirmationDialog>
              </div>

              </div>
              </div>
              </>
            )}
                  <div className="col pt-3">
              <textarea id="Input64Img" ref={ref64} name="Input64Img" hidden />
              {/* {usrid ? (
               <ImageDisplay
               imageId={usrid}
               onImageCheck={handleImageCheck}
             />
            ) : (
           <p></p>
            )} */}
            </div>
          </div>
        <div className="row row-cols-3 pt-3 pb-4">
        <div className="col" />
        <div className="col text-start  text-nowrap">
          <button type="submit" className="btn btn-secondary font-family-sans-serif">Save Changes</button>
        </div>
      </div>
    
      </form>
        </div>
    </>
  );
};

export default Profile_Public;
