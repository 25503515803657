
import React, { useEffect, useState } from 'react';
import './ProfileSettings.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ImageDisplay from '../ImageDprofileHead';
import { calcDate } from '../calcDate'; // Adjust the path if needed
import moment from 'moment';
function ProfileSettingsHeading() {
  const username = localStorage.getItem('unm');
  const userid = localStorage.getItem('userid');
  const crdate = localStorage.getItem('crdate');
  const today = new Date();
  const [joinstmt, setJoinstmt] = useState('');

  useEffect(() => {
    // Format dates for calcDate function
    const formattedToday = moment(today).format('YYYY-MM-DD');
    const formattedCrDate = moment(crdate);
// console.log('----crdate---'+crdate);
// console.log('----formattedToday---'+formattedToday+'---formattedCrDate--'+formattedCrDate);
    // Calculate date difference using calcDate
    const dateDifference = calcDate(formattedToday, formattedCrDate);
    //console.log('----dateDifference---'+JSON.stringify(dateDifference));
    if (JSON.stringify(dateDifference.result) === '""') {
      setJoinstmt(' today');
    } else {
      setJoinstmt(JSON.stringify(dateDifference.result) + ' ago');
    }
  }, [today, crdate]);

  return (
    <>

  <div className="row"> 
    <div className="col-md-4">
    <div className="cse-maverick text-nowrap font-family-sans-serif">
                      {localStorage.getItem('fname')+' '+localStorage.getItem('lname')}
                    </div>

    </div>
    <div className="col-md-4"></div>
    <div className="col-md-4"></div>
  </div>


  <div className="row"> 
    <div className="col-md-4 table-responsive">
    <table>
    <tr>
                    <td className="d-flex justify-content-start border-0 text-nowrap">
                      <ImageDisplay imageId={userid} />
                    </td>
                    <td className="justify-content-start text-nowrap">
                      <div className="row">
                        <div className="col font-family-sans-serif  text-white text-success-subtle fs-60 fw-normal">
                         <img className="rounded-1 span4 center-block px-2" src="./images/profile/vgeenclock.png" />
                         Online
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-white font-family-sans-serif text-success-subtle fs-60 fw-normal">
                         <img className="rounded-1 span4 center-block px-2" src="./images/profile/vbag.png" />
                          Joined {joinstmt.replace(/['"]+/g, '')} 
                        </div>
                        <div className="col">&nbsp;</div>
                      </div>
                    </td>
                  </tr>
                  </table>
    </div>
    <div className="col-md-4">
 
    <div className="col text-center text-white">
                <h1 className="text-center">Profile Settings</h1>
                <p className="text-center text-success-subtle fs-6 fw-light font-family-sans-serif m-0 px-3 py-2 d-flex align-items-center justify-content-center">
                  Manage your account details and preferences.
                </p>
              </div>
    </div>
    <div className="col-md-4"></div>
  </div>
      <hr
        style={{
          color: 'rgba(256,256,256, 0.3)',
          backgroundColor: 'rgba(256,256,256, 0.3)',
          height: 1,
          width: '100%',
        }}
      />
    </>
  );
}

export default ProfileSettingsHeading;
