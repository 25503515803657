// export default App;
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import useScreenSize from "use-screen-size";
import { PortfolioProvider } from "./PortfolioContext"; // Import the PortfolioProvider
import Layout from "./page/Layout";
import Home from "./Home";
import Blogs from "./page/Blogs";
import ExploretrendRunner from "./page/landing/Exploretrendrunner";
import Explorefirstedge from "./page/landing/Explorefirstedge";
import Alertmanagement from "./page/alertmanagement/index";
import Subcmanagement from "./page/subcriptmanagement/index";
import Contact from "./page/Contact";
import NoPage from "./page/NoPage";
import Login from "./page/login/index";
import UserRegister from "./page/login/userregister";
import AboutUs from "./page/about-us/index";
import Test from "./page/portfolio/linechartold";
import ProfileSettings from "./page/ProfileSettings/index";
import CounTry from "./page/ProfileSettings/couintry";
import ProtectedRoute from "./ProtectedRoute";
import Portfolio from "./page/portfolio/index";
import PortfolioPerformanceToday from "./page/portfolio/PortfolioPerformanceToday";
import PortfolioPerformanceLMonth from "./page/portfolio/PortfolioPerformanceLMonth";
import PortfolioPerformanceLThreeM from "./page/portfolio/PortfolioPerformanceLThreeM";
import Portfolio_gain from "./page/portfolio/gain/index";
import Portfolio_gain_today from "./page/portfolio/gain/PortfolioPerformanceToday";
import PortfolioPerformanceLMonth_gain from "./page/portfolio/gain/PortfolioPerformanceLMonth";
import PortfolioPerformanceLThreeM_gain from "./page/portfolio/gain/PortfolioPerformanceLThreeM";
import Portfolio_apd from "./page/portfolio/apd/index";
import Resetpw from "./page/login/forgotpw";
import Forgotpw from "./page/forgotpw/index";

function App() {
  const size = useScreenSize();
  const [screenSize, setScreenSize] = useState("");

  return (
    <PortfolioProvider> {/* Wrap the provider here */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="forget" element={<Forgotpw />} />
          <Route path="resetpw" element={<Resetpw />} />
          <Route path="contact" element={<Contact />} />
          <Route path="padmini" element={<Test />} />
          <Route path="country" element={<CounTry />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="explore" element={<ExploretrendRunner />} />
          <Route path="firstedge" element={<Explorefirstedge />} />
          <Route path="login" element={<Login />} />
          <Route path="userregister" element={<UserRegister />} />
          <Route path="portfolio" element={<ProtectedRoute><Portfolio /></ProtectedRoute>} />
          <Route path="portfolio_today" element={<ProtectedRoute><PortfolioPerformanceToday /></ProtectedRoute>} />
          <Route path="portfolio_last-month" element={<ProtectedRoute><PortfolioPerformanceLMonth /></ProtectedRoute>} />
          <Route path="portfolio_last-three-months" element={<ProtectedRoute><PortfolioPerformanceLThreeM /></ProtectedRoute>} />
          <Route path="portfolio_gain" element={<ProtectedRoute><Portfolio_gain /></ProtectedRoute>} />
          <Route path="portfolio_gain_today" element={<ProtectedRoute><Portfolio_gain_today /></ProtectedRoute>} />
          <Route path="portfolio_gain_last-month" element={<ProtectedRoute><PortfolioPerformanceLMonth_gain /></ProtectedRoute>} />
          <Route path="portfolio_gain_last-three-months" element={<ProtectedRoute><PortfolioPerformanceLThreeM_gain /></ProtectedRoute>} />
          <Route path="portfolio_apd" element={<ProtectedRoute><Portfolio_apd /></ProtectedRoute>} />
          <Route path="profilesettings" element={<ProtectedRoute><ProfileSettings /></ProtectedRoute>} />
          <Route path="alertmng" element={<ProtectedRoute><Alertmanagement /></ProtectedRoute>} />
          <Route path="subcript" element={<ProtectedRoute><Subcmanagement /></ProtectedRoute>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </PortfolioProvider>
  );
}

export default App;

