import React, { useState, useEffect ,useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ConfirmationDialog_add from "../ConfirmationDialog_add.js";
import ConfirmationDialog_minus from "../ConfirmationDialog_minus.js";
import EditDialog_minus from "./EditDialog_minus.js";
import EditDialog_add from "./EditDialog_add.js";
import FinancialData from "./FinancialData.js";
import { usePortfolio } from "../../PortfolioContext";
// import './portfolio.css';
const CurrentPortfolio = ({ initialUserData }) => {
  const { stocks,setStocks, currentPrices ,volumes } = usePortfolio();
  const [updateData, setUpdateData] = useState(null);
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const localLogoImagePath = "/images/companylogos";
  // console.log('--stocks---'+JSON.stringify(stocks));
  const removeData = async (id) => {
    try {
      const res = await axios.delete(Base_URL + "/portfolio/delstock", {
        params: { ordid: id }
      });
      toast.success("Stock Data Successfully deleted.");
      setStocks(prevStocks => prevStocks.filter(stock => stock.ordid !== id)); // Use context setter
       // navigate('/temp'); // Navigate to a temporary route
      // navigate('/portfolio'); // Navigate back to refresh 
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmation = (id) => {
    removeData(id);
  };

  const handleStockModal_add = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_add(true);
  };

  const handleStockModal_minus = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_minus(true);
  };

  const closeEditDialog_add = () => {
    setEditDialogOpen_add(false);
    setUpdateData(null);
  };

  const closeEditDialog_minus = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };
  let loadImage = function(variable) {
    const url_image = `/images/companylogos/${variable}.svg`;

    return (
        <img
            className="rectangle-154 m-1"
            style={{
                borderRadius: '8px',
                flexShrink: 0,
                width: '48px',
                height: '48px',
                position: 'relative',
                objectFit: 'cover',
            }}
            src={url_image}
            alt="Company Logo"
            onError={(e) => { e.target.src = '/images/companylogos/default-logo.png'; }}
        />
    );
};
  return (
    <>
    <div className="container">
      <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
        CURRENT PORTFOLIO
      </p>
      <div className="row h-25 w-100 px-3">
            <div className="text-white text-opacity-20 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-0 py-2 px-3" style={{ backgroundColor: 'black', width: '100%', height: '270px', overflow: 'auto' }}>

<div className="cpfcssh2 cpfcssh14">
<div className="cpfcssh11">
          <div className="cpfcssh3">
            Stock
          </div>
        </div>
        <div className="cpfcssh2 cpfcssh13">
          <div className="cpfcssh3">
            Quantity
          </div>
        </div>
        <div className="cpfcssh2 cpfcssh12">
          <div className="cpfcssh4">
            Avg. Price Purchased
          </div>
        </div>
        <div className="cpfcssh2 cpfcssh12">
          <div className="cpfcssh3">
            Current Price
          </div>
        </div>
        <div className="cpfcssh2 cpfcssh12">
          <div className="cpfcssh3">
            Action
          </div>
        </div>
       </div>

       {stocks.map(stock => (
            <>      
                
       <div  key={stock.ordid}  className="cpfcssh1"/>
              <div className="cpfcssh5">
           <div className="cpfcssh6">
                      {loadImage(stock.ctick)}
              <div className="cpfcssh10">
               <div className="cpfcssh15">
               {stock.cname}
               </div>
               <div className="cpfcssh16">
               {stock.ctick}
               </div>
             </div>
           </div>
           <div className="cpfcssh17">
             <div className="cpfcssh18">
             {(stock.csemco_quantity).toLocaleString()}
             </div>
             <div style={{ "width": "5px"}}></div>
           </div>
           <div className="cpfcssh8">
             <div className="cpfcssh7">
             {(stock.avgprice).toLocaleString()}
             </div>
           </div>
           <div className="cpfcssh8">
             <div className="cpfcssh7">
            {currentPrices.find((price) => price.symbol === stock.ctick) ? ((currentPrices.find((price) => price.symbol === stock.ctick)).close_prices.cptoday).toFixed(2).toLocaleString() : "N/A"}
             </div>
           </div>
           <div className="cpfcssh8">
                         <ConfirmationDialog_add
                      title="Confirmation"
                      description="Are you sure you want to proceed?"
                      response={() => handleConfirmation(stock.ordid)}>
                      {(showDialog) => (
                        <i className="bi bi-trash" onClick={showDialog} style={{ color: 'rgb(255, 0, 0)', fontSize: '1rem' }}></i>
                      )}
                    </ConfirmationDialog_add>
                    &nbsp;
                    <TwitterShareButton
                      title={`Hey, I just purchased ${stock.csemco_quantity} stocks from ${stock.cname}. My current avg. price is ${stock.avgprice}`}
                      url={'https://www.stockwiseanalytics.com'}
                      hashtags={['', '']}
                    >
                      <i className="bi bi-twitter-x" style={{ color: '#fff', fontSize: '1rem' }}></i>
                    </TwitterShareButton>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_add(stock)}>
                      <i className="bi bi-file-earmark-plus" style={{ color: 'gold', fontSize: '1rem' }}></i>
                    </a>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_minus(stock)}>
                      <i className="bi bi-file-earmark-minus" style={{ color: 'cyan', fontSize: '1rem' }}></i>
                    </a>
           </div>
         </div></>
 ))}



        </div>
      </div>
      </div>
      <EditDialog_add
        open={editDialogOpen_add}
        onClose={closeEditDialog_add}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          stocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
      <EditDialog_minus
        open={editDialogOpen_minus}
        onClose={closeEditDialog_minus}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          stocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
    </>
  );
};

export default CurrentPortfolio;