// export default ImageDisplayprofile;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../Components/BaseURL.js';
import './ProfileSettings/ProfileSettings.css';
const URL = Base_URL;

const ImageDisplayprofile = ({ imageId, onImageCheck = () => {} }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      if (!imageId) {
        console.warn('No imageId provided. Using default image.');
        return;
      }

      try {
        const response = await axios.get(`${URL}/upimage/image/${imageId}`, {
          responseType: 'arraybuffer',
        });
        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        const imageSrc = `data:${response.headers['content-type'].toLowerCase()};base64,${base64Image}`;
      //  if (imageSrc === 'data:null;base64,' || imageSrc === 'data:image/png;base64,' ) {
          if ((imageSrc === 'data:null;base64,') || (imageSrc === 'data:image/png;base64,') || (imageSrc === 'data:image/gif;base64,')|| (imageSrc === 'data:image/jpeg;base64,')) { 
          console.log('Fetched image is empty, using default image.');
          setImageSrc('');
          onImageCheck(true); // Notify parent that the image is empty
        } else {
          setImageSrc(imageSrc);
          onImageCheck(false); // Notify parent that the image is not empty
        }
      } catch (error) {
        console.error('Error fetching image:', error);
        onImageCheck(true); // Consider it empty if there's an error
      }
    };

    if (imageId) {
      fetchImage();
    }
  }, [imageId, onImageCheck]);

  return (
    <div>
      {imageSrc ? (
        <img
          className="rounded-1 span4 center-block"
          style={{ width: '120px', height: 'auto' }}
          src={imageSrc}
          alt="Profile"
        />
      ) : (
        <img
        className="rounded-1 span4 center-block" style={{"border":"1px dashed #3697e0","borderRadius":"5px","padding":"10px 10px","textAlign":"center","width":"110px","height":"110px"}}
        src="images/profile/default-user.png"
        alt="Profile"
      />
      )}
    </div>
  );
};

export default ImageDisplayprofile;

