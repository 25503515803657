// export default Linechart;
import React, { useState ,useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { chartColors } from './colors.js';
import { useWindowSize } from "@uidotdev/usehooks";
import { usePortfolio } from "../../PortfolioContext";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
const Linechart = ({ initialUserData }) => {
  const size = useWindowSize();
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const { stocks, currentPrices ,currentVolumes } = usePortfolio();
 const labels = ["3 Months ago", "Month ago", "Week ago", "Today"];
 const getDataPoints = (label, stock, matchingData) => {
    const quantity = stock.csemco_quantity;
    const avgPrice = stock.avgprice;

    switch (label) {
      case "3 Months ago":
         return ((parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Month ago":
         return ((parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Week ago":
         return ((parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Today":
         return ((parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity).toFixed(2);
      default:
        return 0;
    }
  };

  const data = labels.map((label) => {
    const dataPoint = { label };
    stocks.forEach(stock => {
      const matchingData = currentPrices.find(item => item.symbol === stock.ctick);
      if (matchingData) {
        dataPoint[stock.ctick] = getDataPoints(label, stock, matchingData);
      }
    });
    return dataPoint;
  });


 // Process to find min and max
  const values = data.flatMap(entry =>
      Object.keys(entry)
          .filter(key => key !== 'label') // Exclude label
          .map(key => Number(entry[key])) // Convert to numbers
  );
const smallest = Math.min(...values);
const biggest = Math.max(...values);
const [opacity, setOpacity] = useState({});
console.log(smallest);
console.log(biggest);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const sortedPayload = [...payload].sort((a, b) => b.value - a.value);
      return (
        <>   

      <div className="custom-tooltip px-1 py-1 bg-white bg-opacity-100 rounded-3" style={{backgroundColor: "#fff", width:'auto',border: "1px solid #ccc" }}>
      <ul>
              <p className="label align-items-center p-1 text-nowrap" style={{color: "#000000",fontFamily: 'sans-serif',fontWeight: "bold" }}>{label}</p>
        {sortedPayload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex'}}>
            <li style={{ backgroundColor: entry.color, color: entry.color,fontFamily: 'sans-serif',fontSize:'20px', lineHeight: '14px', verticalAlign:'middle'}}></li>
            <span className="span-css" style={{ color: 'black', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '0.65rem' }}>{entry.name}: </span>
            <span className="span-css" style={{float:'right', color: "#000000", fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>
                 {new Intl.NumberFormat('en-US', {
                   style: 'decimal',
                   maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(entry.value)}
               </span>
          </div>
        ))}
          </ul>
      </div>
    
      </>
      );
    }
    return null;
  };


  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity((prev) => ({ ...prev, [dataKey]: 1 })); // Reduce opacity for hovered item
    },
    [setOpacity]
  );
  
  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity((prev) => ({ ...prev, [dataKey]: 0.5 })); // Restore opacity for unhovered item
    },
    [setOpacity]
  );
  
   
  return (
    <>
<div className="container">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
   Gain (LKR)</p>
     <ResponsiveContainer width="100%" height={300}>
      {/* aspect={3} */}
      <LineChart data={data}>
         <CartesianGrid strokeDasharray="3 3" opacity={0.14} />
       <XAxis dataKey="label" interval={0} angle={0} padding={{ left: 30, right: 30 }} textAnchor="middle" dx={-5} dy={5}
          label={{
            fontFamily: 'sans-serif',
            fontSize: '0.65rem',
          }} />
 <YAxis
  padding={{ top: 5 , bottom:5 }}
  interval={0}
  tickSize={2}
  width={100}  
  scale="auto"
  domain={[smallest, biggest]}
  tickFormatter={(value) => 
     new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(value)}
  label={{ value: 'LKR', angle: -90, position: 'insideLeft' ,fontFamily: 'sans-serif',fontSize: '0.65rem',}}
/>
        <Tooltip content={<CustomTooltip />}/>
         <Legend
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
          layout="horizontal" verticalAlign="top" align="center"
          wrapperStyle={{ fontFamily: 'sans-serif',fontSize: '0.65rem'}} 
      
         />
         {stocks.map((stock, index) => (
           <Line
             key={stock.ctick}
             dataKey={stock.ctick}
             name={stock.ctick}
             type="monotone"
             stroke={chartColors[index % chartColors.length]}
             dot={false} // Disable dots on the line
             opacity={opacity[stock.ctick] ?? 1}
              // Use opacity from state or default to 1
           />
         ))}
       </LineChart>
     </ResponsiveContainer>
     </div>
     <Browser size={size} />
    </>
  );
};

export default Linechart;